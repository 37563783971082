import { useEffect } from "react";

const MouseGlow = () => {
  const handleMouseMove = (e) => {
    const glowEffect = document.querySelector(".mouse-glow");
    if (glowEffect) {
      glowEffect.style.background = `radial-gradient(
        800px circle at ${e.clientX}px ${e.clientY}px,
        rgba(29, 78, 216, 0.18),
        transparent 40%
      )`;
    }
  };

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
    return () => window.removeEventListener("mousemove", handleMouseMove);
  }, []);

  return <div className="mouse-glow" />;
};

export default MouseGlow;
